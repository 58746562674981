import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Bocklemuend: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Bocklemuend,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Bocklemünd"
          d="M291.9,406.9l3.1-2.9l15,24l-4,50l-19,54l-22-64l-14,2l-10,10h-18l-13.6-12.2l6.9-6.6c1.2-2.7,4.9-6.8,6.6-9.2 l-2.6-2.4c3.2-4.4,9.5-13,12.4-17.7c0.3,0.1,0.9,0.4,1.1,0.6l3-6.6c0.2,0.1,24,10,24.2,10.1L291.9,406.9z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 232.5282 455.5508)"
        >
          Bocklemünd
        </text>
      </g>
    </>
  );
});
